export const ROUTES = Object.freeze({
  HOME: "/",
  LOGIN: "/login",
  PAGE_NOT_FOUND: "/page-not-found",
  RESET_PASSWORD: "/reset-password",
  CARDS: "/dashboard",
  TENANTS: "/tenants",
  SCHOOLS: "/schools",
  STUDENTS: "/students",
  USERS: "/users",
  GRADES: "/grades",
  PRODUCTS: "/products",
  ROLES: "/roles",
  EXAMS: "/exams",
  AGENTS: "/agents",
  PROGRAMS: "/programs"
});
