import React, { useContext, useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CommonApiDataContext } from "../../context/CommonApiDataContext";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const steps = ["Select CSV", "Review", "Upload"];

const BulkUpload = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [file, setFile] = useState({ content: null, name: null, rows: null });

    const { handleOpenSnackbar } = useContext(CommonApiDataContext);

    // const columns = [
    //     { field: "id", headerName: "ID", width: 70 },
    //     { field: "firstName", headerName: "First name", width: 130 },
    //     { field: "lastName", headerName: "Last name", width: 130 },
    //     {
    //         field: "age",
    //         headerName: "Age",
    //         type: "number",
    //         width: 90,
    //     },
    //     {
    //         field: "fullName",
    //         headerName: "Full name",
    //         description: "This column has a value getter and is not sortable.",
    //         sortable: false,
    //         width: 160,
    //         valueGetter: (value, row) => `${row?.firstName || ""} ${row?.lastName || ""}`,
    //     },
    // ];

    // const rows = [
    //     { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    //     { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    //     { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    //     { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    //     { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
    //     { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
    //     { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    //     { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
    //     { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
    // ];

    const getRows = () => {
        if (file && file.rows && file.rows.length > 0) {
            // Extract headers
            const headers = file.rows[0];

            // Remove the first element (headers) from the array

            const rowsWithoutHeaders = file.rows.slice(1);

            // Initialize an array to store formatted data
            const data2 = [];

            // Counter for IDs
            let idCounter = 1;

            // Iterate over each array in data and transform it into an object
            rowsWithoutHeaders.forEach((arr) => {
                const obj = {};
                headers.forEach((header, index) => {
                    obj[header] = arr[index] || ""; // Use empty string if the value is undefined
                });
                obj["id"] = idCounter++;
                data2.push(obj);
            });

            return data2;
        } else {
            return [];
        }
    };

    const getTableHeaders = () => {
        if (file && file.rows && file.rows.length > 0) {
            return file.rows[0].map((header) => ({
                field: header,
                headerName: header,
                width: 130,
                editable: true,
            }));
        } else {
            return [];
        }
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ marginLeft: "auto" }}>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                  steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
        setFile({ content: null, name: null, rows: null });
    };

    const handleFileChange = (event) => {
        try {
            const file = event.target.files[0];
            setFile((prev) => ({ ...prev, name: file && file?.name }));
            if (!file) {
                handleOpenSnackbar(true, "No file selected", "error");
            }
            if (!file.name.endsWith(".csv")) {
                handleOpenSnackbar(true, "Select CSV file format only", "error");
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target.result;
                console.log("File content:", content);
                const rows = content.split("\n").map((row) => row.split(",").map((i) => i.replace("\r", "")));
                setFile((prev) => ({ ...prev, content, rows }));
            };
            reader.readAsText(file);
        } catch (error) {
            handleOpenSnackbar(true, error.message, "error");
        }
    };

    const handleDisableNextbtn = () => {
        if (file.rows && file.rows.length > 0) {
            console.log("1");
            return false;
        } else {
            console.log("2");
            return true;
        }
    };

    const renderSteps = () => {
        switch (activeStep) {
            case 0:
                return (
                    <div className="step-1">
                        <div className="select-file-box">
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}>
                                Select file
                                <VisuallyHiddenInput type="file" accept="text/csv" onChange={handleFileChange} />
                            </Button>
                            {file.name && (
                                <Typography variant="caption" sx={{ display: "inline-block" }}>
                                    {file.name}
                                </Typography>
                            )}
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className="step-2">
                        <div style={{ height: 350, width: "100%", marginTop: "20px" }}>
                            {/* <DataGrid
                                rows={getRows()}
                                columns={getTableHeaders()}
                                editable={true} // Enable editing
                                // Add an event handler for cell edits
                                onEditCellChange={(editCellProps) => {
                                    const updatedRows = [...file.rows]; // Create a copy of the rows
                                    const { id, field, props } = editCellProps;
                                    const rowIndex = updatedRows.findIndex((row) => row.id === id);
                                    if (rowIndex > -1) {
                                        updatedRows[rowIndex][field] = props.value; // Update the value of the cell
                                        setFile((prev) => ({ ...prev, rows: updatedRows })); // Update the state with the new rows
                                    }
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                            /> */}

                            <DataGrid
                                rows={getRows()}
                                columns={getTableHeaders()}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                // checkboxSelection
                                disableRowSelectionOnClick
                            />
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="step-3">
                        <div className="select-file-box">
                            {file.name && (
                                <Typography variant="caption" sx={{ display: "inline-block" }}>
                                    {file.name}
                                </Typography>
                            )}
                            <Button variant="contained" startIcon={<CloudUploadIcon />} sx={{ marginLeft: "10px" }}>
                                Upload
                            </Button>
                        </div>
                    </div>
                );
            default:
                break;
        }
    };

    return (
        <div className="bulk-upload-dlg-box">
            <Stepper nonLinear activeStep={activeStep} sx={{ position: "sticky", zIndex: 3 }}>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit" onClick={handleStep(index)}>
                            {label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <div className="upload-steps">{renderSteps()}</div>
            <div>
                {allStepsCompleted() ? (
                    <React.Fragment>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "600px",
                            }}>
                            <Button onClick={handleReset}>Reset</Button>
                            <Typography>All steps completed - you&apos;re finished</Typography>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", pt: 2 }}>
                            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                                Back
                            </Button>
                            <Box>
                                {activeStep !== steps.length &&
                                    (completed[activeStep] ? (
                                        <Button onClick={handleNext} sx={{ mr: 1 }} disabled={handleDisableNextbtn()}>
                                            Next
                                        </Button>
                                    ) : (
                                        <Button onClick={handleComplete} disabled={handleDisableNextbtn()}>
                                            {completedSteps() === totalSteps() - 1 ? "Finish" : "Next"}
                                        </Button>
                                    ))}
                            </Box>
                        </Box>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export default BulkUpload;
