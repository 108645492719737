import React, { useContext, useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { MenuProps } from "../../assets/css/styles";
import { BasicDatePicker } from "../DatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { ThreeDotsLoader } from "../loader/Loader";
import Autocomplete from "@mui/material/Autocomplete";
import { ThreeDots } from "react-loader-spinner";
import { uploadImage } from "../../utils";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import { CommonApiDataContext } from "../../context/CommonApiDataContext";
const options = [
    { label: "Inmobius", value: "IM" },
    { label: "Infinity Learn", value: "IL" },
];

let apiCalledAt = new Date();

const DynamicForm = (props) => {
    const { closeModal, data, ModalTitle, fields, loader, actions, dta, setData, navId, setobj } = props;
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { products, grades, exams, tenants, roles, handleOpenSnackbar, keys, pushToLogin } =
        useContext(CommonApiDataContext);
    const moduleName = ModalTitle.split(" ")[1];

    useEffect(() => {
        data && setFormData(data);
    }, [data]);

    useEffect(() => {
        setErrors(null);
    }, []);

    const handleChange = async (e, schema, x) => {
        const { name, value, files } = e.target;
        const updatedFormData = { ...formData };

        // updating obj state only in school wrapper to get country, state and city
        setobj && x && setobj((prev) => ({ ...prev, [x]: value?.value }));

        if (Array.isArray(value)) {
            const selectAllIndex = value.indexOf("select all");
            const allOptions = fields.find((field) => field.name === name)?.options || [];

            updatedFormData[name] =
                selectAllIndex !== -1
                    ? updatedFormData[name]?.length === allOptions.length
                        ? []
                        : allOptions
                    : value.filter((option) => option !== "select all");
            schema &&
                schema
                    .validate(updatedFormData[name])
                    .then(() => setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" })))
                    .catch(() =>
                        setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "select at least one option" }))
                    );
        } else if (files) {
            const accessToken = localStorage.getItem("accessToken");
            const fileData = {
                fileobject: files[0],
            };
            setLoading(true);
            const imageLink = await uploadImage(accessToken, fileData);
            updatedFormData[name] = imageLink?.image_url;
            setLoading(false);
        } else {
            updatedFormData[name] = value;

            schema &&
                schema
                    .validate(value)
                    .then(() => setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" })))
                    .catch((error) => setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: error.message })));
        }

        setFormData(updatedFormData);
    };

    // Always genatares the new 5 letter code for each click
    const generateCode = (name, ref, schema) => {
        const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const code = Array.from({ length: 5 }, () => alphabet[Math.floor(Math.random() * alphabet.length)]).join(""); //Array.from is used to create an array with a length of 5, and the join method is used to concatenate the randomly selected characters from the alphabet
        setFormData((prev) => ({ ...prev, [name]: code }));
        schema &&
            schema
                .validate(code)
                .then(() => setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" })))
                .catch((error) => setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: error.message })));
    };

    const getupdatedFormData = () => {
        const updatedFormData = { ...formData };
        for (let i = 0; i < fields.length; i++) {
            const fieldType = fields[i].type;
            const fieldName = fields[i].name;
            if (fieldType === "searchmultiSelect") {
                const ids = formData[fieldName].map((e) => e.value);
                updatedFormData[fieldName] = ids;
            }
            if (fieldType === "searchsingleSelect") {
                updatedFormData[fieldName] = formData[fieldName].value;
            }
            if (fieldName === "source") {
                updatedFormData.created_by = "Sumanth";
            }
            // if (fieldName === "school_name") {
            //     updatedFormData.registration_source = "organic";
            // }
            if (fieldName === "role_type") {
                updatedFormData.role_name = formData?.display_name;
            }
        }

        return updatedFormData;
    };

    const ReplaceIdsWithLabels = (item) => {
        // array of products
        const UPS = item[keys?.prods]?.map((prodId) => {
            const product = products?.find((prod) => prod?.value === prodId);
            return product ? product?.label : prodId;
        });

        // array of grades
        const UGS = item[keys?.grads]?.map((gradeId) => {
            const grade = grades?.find((itemData) => itemData?.value === gradeId);

            return grade ? grade?.label : gradeId;
        });

        // array of roles
        const URS = item[keys?.rols]?.map((roleId) => {
            const role = roles?.find((roleData) => roleData?.value === roleId);
            return role ? role?.label : roleId;
        });

        const UE = exams?.filter((e) => e.value === Number(item[keys?.exm]))[0]?.label; // single exam
        const UP = products?.filter((e) => e?.value === Number(item[keys?.prod]))[0]?.label; // single product
        const UT = tenants?.length > 0 && tenants?.filter((e) => e?.value === Number(item[keys?.tnt]))[0]?.label; // single tenant
        const UG = grades?.filter((e) => e?.value === Number(item[keys?.grad]))[0]?.label; // single grade
        const US = options?.filter((e) => e.value === item[keys?.src])[0]?.label; // single source

        const result = {
            ...item,
        };

        // Conditionally add properties based on keys
        if (keys?.rols) {
            result[keys?.rols] = URS;
        }
        if (keys?.prods) {
            result[keys?.prods] = UPS;
        }
        if (keys?.grads) {
            result[keys?.grads] = UGS;
        }
        if (keys?.exm) {
            result[keys?.exm] = UE;
        }
        if (keys?.prod) {
            result[keys?.prod] = UP;
        }
        if (keys?.tnt) {
            result[keys?.tnt] = UT;
        }
        if (keys?.grad) {
            result[keys?.grad] = UG;
        }
        if (keys?.src) {
            result[keys?.src] = US;
        }

        return result;
    };

    const handleSubmit = async (e) => {
        setLoading(true);
        const accessToken = localStorage.getItem("accessToken");
        e.preventDefault();
        const updatedFormData = getupdatedFormData();

        if (new Date() - apiCalledAt > 500) {
            if (data) {
                try {
                    apiCalledAt = new Date();

                    const res = await actions?.update(accessToken, updatedFormData);
                    closeModal(false);
                    const UD = dta.map((e) => {
                        if (e[navId] === res[navId]) {
                            return ReplaceIdsWithLabels(res);
                        }
                        return e;
                    });
                    setData(UD);
                    setLoading(false);
                    handleOpenSnackbar(true, `${moduleName} Updated Successfully`, "success");
                } catch (error) {
                    setLoading(false);
                    setErrors(error?.response?.data?.detail);
                    error?.response?.data?.detail === "Expired Signature" && setTimeout(() => pushToLogin(), 2000);
                }
            } else {
                setLoading(true);
                apiCalledAt = new Date();

                try {
                    const res = await actions?.create(accessToken, updatedFormData);
                    setData((prev) => [ReplaceIdsWithLabels(res), ...prev]);
                    closeModal(false);
                    setLoading(false);
                    handleOpenSnackbar(true, `${moduleName} Created Successfully`, "success");
                } catch (error) {
                    setLoading(false);
                    setErrors(error?.response?.data?.detail);
                    error?.response?.data?.detail === "Expired Signature" && setTimeout(() => pushToLogin(), 2000);
                }
            }
        }
    };

    const handleReset = (e) => {
        e.preventDefault();
        setFormData({});
        setValidationErrors({});
    };

    const hasValidationErrors = () => {
        for (const fieldName in validationErrors) {
            if (validationErrors.hasOwnProperty(fieldName) && validationErrors[fieldName] !== "") {
                return true;
            }
        }
        return false;
    };

    const buttonDisabled = () => {
        let flag = false;
        for (let i = 0; i < fields.length; i++) {
            const fieldName = fields[i].name;
            const isMandatory = fields[i].mandatory;
            if (isMandatory && !formData[fieldName]) {
                flag = true; // Set flag to true if any mandatory field is not filled
                break; // If any mandatory field is not filled, disable the button
            }
            if (isMandatory && hasValidationErrors()) {
                flag = true;
                break;
            }
        }
        return flag;
    };

    return (
        <form onSubmit={handleSubmit} onReset={handleReset} className="view-edit-modal-body">
            <div className="modal-title-close-btn-box">
                <h5 className="modal-title">{ModalTitle}</h5>
                <IconButton color="primary" aria-label="add to shopping cart" onClick={() => closeModal(false)}>
                    <CloseOutlinedIcon />
                </IconButton>
            </div>
            {loader ? (
                ThreeDotsLoader(60, 60, 9, "#48aafa")
            ) : (
                <div className="modal-data-box">
                    {fields.map((field) => {
                        return (
                            <div className="col-md-12 mb-3" key={field.name}>
                                {field.type === "multiSelect" ? (
                                    <FormControl sx={{ width: "100%" }} error={!!validationErrors[field.name]}>
                                        <InputLabel>
                                            {field.label}
                                            {field.mandatory && <span>*</span>}
                                        </InputLabel>
                                        <Select
                                            multiple
                                            name={field.name}
                                            type="multiSelect"
                                            disabled={data ? !field?.editable : false}
                                            value={formData[field.name] || []}
                                            onChange={(e) => handleChange(e, field.schema)}
                                            input={<OutlinedInput label={field.label} />}
                                            renderValue={(selected) => selected.map((e) => e?.label).join(", ")}
                                            MenuProps={MenuProps}>
                                            {/* Select All Option */}
                                            <MenuItem value="select all">
                                                <Checkbox
                                                    checked={formData[field.name]?.length === field?.options?.length}
                                                    indeterminate={
                                                        formData[field.name]?.length > 0 &&
                                                        formData[field.name]?.length < field?.options?.length
                                                    }
                                                />
                                                <ListItemText primary="Select All" />
                                            </MenuItem>
                                            {field?.options?.map((name) => (
                                                <MenuItem key={name.value} value={name}>
                                                    <Checkbox checked={formData[field.name]?.indexOf(name) > -1} />
                                                    <ListItemText primary={name.label} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{validationErrors[field.name]}</FormHelperText>
                                    </FormControl>
                                ) : field.type === "searchmultiSelect" ? (
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            multiple
                                            disablePortal
                                            fullWidth
                                            limitTags={2}
                                            id={`floating${field.name}`}
                                            options={["select all", ...field.options]}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={`${field?.label}${field.mandatory && " *"}`}
                                                    error={!!validationErrors[field.name]}
                                                    helperText={validationErrors[field.name]}
                                                />
                                            )}
                                            value={Array.isArray(formData[field.name]) ? formData[field.name] : []}
                                            onChange={(e, newValue) => {
                                                const allValues = field.options.map((option) => option);
                                                const updatedValue = newValue.includes("select all")
                                                    ? allValues
                                                    : newValue;
                                                handleChange(
                                                    { target: { name: field?.name, value: updatedValue } },
                                                    field.schema,
                                                    field.change
                                                );
                                            }}
                                        />
                                        {/* {validationErrors[field?.name] && (
                                            <div className="text-danger">{validationErrors[field?.name]}</div>
                                        )} */}
                                    </FormControl>
                                ) : field.type === "singleSelect" ? (
                                    <FormControl fullWidth>
                                        <InputLabel>
                                            {field.label}
                                            {field.mandatory && <span>*</span>}
                                        </InputLabel>
                                        <Select
                                            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                                            labelId={`floating${field.name}`}
                                            id={`floating${field.name}`}
                                            name={field.name}
                                            disabled={data ? !field?.editable : false}
                                            value={formData[field?.name] || ""}
                                            label={field.label}
                                            onChange={(e) => handleChange(e, field?.schema)}>
                                            {field?.options.map((field) => (
                                                <MenuItem
                                                    sx={{ padding: "15px" }}
                                                    key={field?.value}
                                                    value={field?.value}>
                                                    {field?.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ) : field.type === "searchsingleSelect" ? (
                                    <Autocomplete
                                        disablePortal
                                        fullWidth
                                        disabled={data ? !field?.editable : false}
                                        id={`floating${field.name}`}
                                        options={field?.options}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={`${field?.label}${field.mandatory && " *"}`}
                                                error={!!validationErrors[field.name]}
                                                helperText={validationErrors[field.name]}
                                            />
                                        )}
                                        value={formData[field?.name] || null}
                                        onChange={(e, newValue) =>
                                            handleChange(
                                                { target: { name: field.name, value: newValue } },
                                                field?.schema,
                                                field?.change
                                            )
                                        }
                                    />
                                ) : field.type === "file" ? (
                                    <FormControl fullWidth>
                                        <TextField
                                            id={`floating${field.name}`}
                                            name={field.name}
                                            type={field?.type}
                                            fullWidth
                                            onChange={(e) => handleChange(e, field.schema)}
                                            label={field.label}
                                            inputProps={{ accept: "image/*" }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                ) : field.name === "dob" ? (
                                    <BasicDatePicker
                                        name={field.name}
                                        handleChange={(e) => handleChange(e)}
                                        value={formData[field.name] || null}
                                    />
                                ) : field.type === "radio" ? (
                                    <FormControl sx={field?.directionStyles}>
                                        <FormLabel id="demo-row-radio-buttons-group-label">{field.label}</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name={field.name}
                                            value={formData[field.name] || ""}
                                            onChange={(e) => handleChange(e)}>
                                            {field?.options.map((opt) => (
                                                <FormControlLabel
                                                    key={opt.value}
                                                    value={opt.value}
                                                    control={<Radio size="small" />}
                                                    label={opt.label}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                ) : field.type === "image" ? (
                                    <div className="create--form--plain-text-field-box image">
                                        <FormLabel id="demo-row-radio-buttons-group-label">{field.label}</FormLabel>
                                        <div className="image-box">
                                            <input
                                                name={field.name}
                                                id="ProfileImg"
                                                type="file"
                                                accept="image/jpeg, image/png, image/gif"
                                                style={{ display: "none" }}
                                                onChange={handleChange}
                                            />
                                            <label htmlFor="ProfileImg" className="profile-img">
                                                {formData[field.name] ? (
                                                    <img src={formData[field.name]} alt="" className="profile-img" />
                                                ) : (
                                                    <Avatar src="" className="profile-img" />
                                                )}
                                            </label>
                                        </div>
                                    </div>
                                ) : field.type === "heading" ? (
                                    <div style={field?.directionStyles}>
                                        <h5>{field.label1}</h5>
                                        <h5>{field.label2}</h5>
                                    </div>
                                ) : field.type === "description" ? (
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={3}
                                        name={field.name}
                                        value={formData[field.name] || ""}
                                        required={field.mandatory}
                                        id={`floating${field.name}`}
                                        label={field.label}
                                        disabled={data ? !field?.editable : false}
                                        inputProps={{ maxLength: field.maxChar }}
                                        error={!!validationErrors[field.name]}
                                        helperText={validationErrors[field.name]}
                                        onChange={(e) => handleChange(e, field.schema)}
                                    />
                                ) : (
                                    <div className="create--form--plain-text-field-box">
                                        <TextField
                                            required={field.mandatory}
                                            id={`floating${field.name}`}
                                            name={field.name}
                                            disabled={data ? !field?.editable : false}
                                            value={formData[field.name] || ""}
                                            type={
                                                field?.type === "password"
                                                    ? field.type === "password"
                                                        ? showPassword
                                                            ? "text"
                                                            : "password"
                                                        : field.type
                                                    : field?.type
                                            }
                                            fullWidth
                                            inputProps={{
                                                maxLength: field.maxChar,
                                                inputMode: "tel",
                                            }}
                                            onChange={(e) => handleChange(e, field.schema)}
                                            // label={`${field.label}*`}
                                            label={field.label}
                                            variant="outlined"
                                            error={!!validationErrors[field.name]}
                                            helperText={validationErrors[field.name]}
                                            InputProps={
                                                field.type === "password"
                                                    ? {
                                                          endAdornment: (
                                                              <InputAdornment position="end">
                                                                  <IconButton
                                                                      onClick={() => setShowPassword((prev) => !prev)}>
                                                                      {showPassword ? (
                                                                          <VisibilityIcon />
                                                                      ) : (
                                                                          <VisibilityOffIcon />
                                                                      )}
                                                                  </IconButton>
                                                              </InputAdornment>
                                                          ),
                                                      }
                                                    : null
                                            }
                                        />

                                        {field?.button && (
                                            <Button
                                                className="field-btn"
                                                type="button"
                                                // variant="outlined"
                                                disabled={data ? !field?.editable : !formData[field.refField]}
                                                onClick={() => generateCode(field.name, field.refField, field.schema)}
                                                size="small">
                                                {field?.button}
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    {errors && <p className="text-danger">{errors}</p>}
                    <div className="modal-btn-box">
                        <Button variant="outlined" type="reset">
                            Reset
                        </Button>
                        {loading === true ? (
                            <button className="loading-button">
                                <ThreeDots type="Oval" color="#4154f1" height={30} width={50} radius={5} />
                            </button>
                        ) : (
                            <Button variant="outlined" type="submit" disabled={buttonDisabled()}>
                                {data ? "Update" : "Submit"}
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </form>
    );
};

export default DynamicForm;
