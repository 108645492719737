import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Main } from "../Main";
import DynamicSnackBar from "../SnackBar";
import { Tenants, Grades, Users, Schools, Products, Roles, Exams, Agents, Programs } from "../../pages";
import { GetRoles, getDashboardCount, getExams, getGrades, getProducts, getTenantData } from "../../utils";
import { CommonApiDataContext } from "../../context/CommonApiDataContext";
import MainDashboard from "../cards/Dashboard";

const prods = [
    { label: "Learn", value: 300 },
    { label: "KHub", value: 700 },
    { label: "Meta", value: 200 },
    { label: "Inmobius", value: 100 },
    { label: "IL Schools", value: 500 },
];

const Dashboard = ({ children, pageTitle }) => {
    // Mantaining Globle state accross all the components
    const [snackbarData, setSnackbarData] = useState({ open: false, msg: "", type: "" });
    const [roles, setRoles] = useState([]);
    const [products, setProducts] = useState([]);
    const [grades, setGrades] = useState([]);
    const [exams, setExams] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [keys, setKeys] = useState([]);
    const [access, setAccess] = useState(null);
    const [dashboardData, setDashboardData] = useState({});
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const agentAccess = localStorage.getItem("access");
        agentAccess ? setAccess(agentAccess) : handleOpenSnackbar(true, "Role missing, please login again!", "error");
        if (!agentAccess) {
            pushToLogin();
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Firing all APIs at a time
                const [dashboardCounts, fetchedRoles, fetchedProducts, fetchedGrades, fetchedExams, fetchedTenants] =
                    await Promise.all([
                        pageTitle === "Dashboard" && getDashboardCount(localStorage.getItem("accessToken")),
                        GetRoles(1, 100),
                        getProducts(1, 100),
                        getGrades(),
                        getExams(localStorage.getItem("accessToken"), 1, 100),
                        (pageTitle === "Schools" || pageTitle === "Users") && getTenantData(), // Tenants Data only required for Schools, Users modules
                    ]);

                // Method to get data in [{label: label1, value: value1, active: true}, {label: label2, value: value2, active: false}]
                const getUpdatedList = (list, name, id, active) => {
                    const updatedList = list?.map((item) => ({
                        label: item[name],
                        value: item[id],
                        active: item[active],
                    }));
                    return updatedList;
                };

                setDashboardData(dashboardCounts);

                const updatedTenants =
                    (pageTitle === "Schools" || pageTitle === "Users") &&
                    getUpdatedList(fetchedTenants, "tenant_name", "tenant_id", "is_active");
                setTenants(updatedTenants);

                const updatedRoles = getUpdatedList(fetchedRoles?.roles, "role_name", "role_id", "active");
                setRoles(updatedRoles);

                const updatedProducts = getUpdatedList(
                    fetchedProducts?.products,
                    "product_name",
                    "product_id",
                    "is_active"
                );

                //@TODO: to be removed by moving production
                const UP = pageTitle === "Users" ? prods : updatedProducts;
                setProducts(UP);

                const updatedGrades = getUpdatedList(fetchedGrades?.grades, "grade_name", "grade_id", "is_active");
                setGrades(updatedGrades);

                const updatedExams = getUpdatedList(fetchedExams?.exams, "name", "exam_id", "active");
                setExams(updatedExams);
                setLoading(false);
            } catch (error) {
                handleOpenSnackbar(true, error?.response?.data?.detail, "error");
                error?.response?.data?.detail === "Expired Signature" && setTimeout(() => pushToLogin(), 3000);
                setLoading(false);
                console.log(error);
            }
        };

        // Since this common API data is not required for Exams, Roles and Products, then we not firing the APIs
        pageTitle !== "Exams" && pageTitle !== "Roles" && pageTitle !== "Products" && fetchData();
    }, [pageTitle]);

    // To Know the User Role
    // useEffect(() => {
    //     const getUserDto = async () => {
    //         try {
    //             const userDTO = await getAgentDTO(localStorage.getItem("accessToken"));
    //             const roleIds = userDTO[0].roles.map((role) => role.roleId);
    //             const roleNames = userDTO[0].roles.map((role) => role.roleName);
    //             const roleCodes = userDTO[0].roles.map((role) => role.roleCode);

    //             // method to know if the user is admin or not
    //             const isAdmin = (list) => {
    //                 const roles = list.map((role) => role.toLowerCase());
    //                 // Check if the admin is in the list of roles array
    //                 return roles.some((keyword) => keyword.includes("admin"));
    //             };

    //             const superAdmin = roleIds.includes(5); // 5 is the Super Admin role
    //             const admin = isAdmin(roleNames); // all Admin roles
    //             const inmobiusUser = roleCodes.some((keyword) => keyword.includes("IMR")); // IMR is role code of Inmobius Rep
    //             const inmobiusSuperAdmin = roleCodes.some((keyword) => keyword.includes("ISA")); // ISA is role code of Inmobius Super Admin

    //             if (superAdmin && admin) {
    //                 setAccess("superAdmin");
    //             } else if (inmobiusSuperAdmin) {
    //                 setAccess("inmobiusSuperAdmin");
    //             } else if (superAdmin && !admin) {
    //                 setAccess("superAdmin");
    //             } else if (!superAdmin && admin) {
    //                 setAccess("admin");
    //             } else if (inmobiusUser) {
    //                 setAccess("inmobiusUser");
    //             } else {
    //                 setAccess("other");
    //             }
    //         } catch (error) {
    //             handleOpenSnackbar(true, error?.response?.data?.detail, "error");
    //             (error?.response?.data?.detail === "Expired Signature" ||
    //                 error?.response?.data?.detail === "Unauthorised" ||
    //                 error?.response?.data?.detail === "Missing Token") &&
    //                 setTimeout(() => pushToLogin(), 3000);
    //             console.log(error);
    //         }
    //     };

    //     getUserDto();
    // }, [pageTitle]);

    const handleOpenSnackbar = (open, msg, type) => {
        setSnackbarData({ open, msg, type });
    };

    const pushToLogin = () => {
        localStorage.setItem("pushed_to_login", "true");
        history.push("/login");
        localStorage.removeItem("sessionStart");
        localStorage.removeItem("user_data");
        localStorage.removeItem("accessToken");
    };

    return (
        // providing globle state values, methods to all modules
        <CommonApiDataContext.Provider
            value={{
                dashboardData,
                roles,
                products,
                grades,
                exams,
                tenants,
                keys,
                access,
                loading,
                setKeys,
                handleOpenSnackbar,
                setAccess,
                pushToLogin,
            }}>
            <main id="main" className="main">
                <Main />
                {children}
                <DynamicSnackBar {...snackbarData} />
            </main>
        </CommonApiDataContext.Provider>
    );
};

const ExamsDashboard = () => {
    return (
        <Dashboard pageTitle="Exams">
            <Exams />
        </Dashboard>
    );
};

const GradesDashboard = () => {
    return (
        <Dashboard pageTitle="Grades">
            <Grades />
        </Dashboard>
    );
};

const ProductDashboard = () => {
    return (
        <Dashboard pageTitle="Products">
            <Products />
        </Dashboard>
    );
};

const SchoolDashboard = () => {
    return (
        <Dashboard pageTitle="Schools">
            <Schools />
        </Dashboard>
    );
};

const TenantDashboard = () => {
    return (
        <Dashboard pageTitle="Tenants">
            <Tenants />
        </Dashboard>
    );
};

const UsersDashboard = () => {
    return (
        <Dashboard pageTitle="Users">
            <Users />
        </Dashboard>
    );
};

const ProgramsDashboard = () => {
    return (
        <Dashboard pageTitle="Programs">
            <Programs />
        </Dashboard>
    );
};

const AgentsDashboard = () => {
    return (
        <Dashboard pageTitle="Agents">
            <Agents />
        </Dashboard>
    );
};

const RolesDashboard = () => {
    return (
        <Dashboard pageTitle="Roles">
            <Roles />
        </Dashboard>
    );
};

const MDashboard = () => {
    return (
        <Dashboard pageTitle="Dashboard">
            <MainDashboard />
        </Dashboard>
    );
};

export {
    ExamsDashboard,
    GradesDashboard,
    ProductDashboard,
    SchoolDashboard,
    TenantDashboard,
    AgentsDashboard,
    UsersDashboard,
    ProgramsDashboard,
    RolesDashboard,
    MDashboard,
};
