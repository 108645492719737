import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Navbar = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [userData, setUserData] = useState({});
    const profileDropdownOpen = Boolean(anchorEl);
    const history = useHistory();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("user_data"));
        setUserData(userData);
    }, []);

    const onLogout = () => {
        localStorage.removeItem("sessionStart");
        history.push("/login");
        localStorage.removeItem("user_data");
        localStorage.removeItem("accessToken");
    };

    return (
        <nav id="header" className="header">
            <h1>Gateway Admin Dashboard</h1>
            <div className="profile-menu-box" onClick={handleClick}>
                {userData?.firstName ? (
                    <Avatar
                        sx={{
                            bgcolor: "#012970",
                            fontSize: "16px",
                        }}>{`${userData?.firstName[0]}${userData?.lastName[0]}`}</Avatar>
                ) : null}
                <span className="profile-name">{`${userData?.firstName} ${userData?.lastName}`}</span>
                <KeyboardArrowDownIcon className={profileDropdownOpen ? "opened" : ""} />
            </div>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={profileDropdownOpen}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <MenuItem onClick={handleClose} sx={{ gap: "10px" }}>
                    <ListItemIcon>
                        <Avatar fontSize="small" />
                    </ListItemIcon>
                    My Profile
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Account Settings
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <HelpOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    Need help?
                </MenuItem>
                <MenuItem onClick={onLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </nav>
    );
};

export default Navbar;
