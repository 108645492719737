import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../../utils/auth";
import { getUsers } from "../../../utils";
import Table from "../../table/Table";
import AgentsWrapper from "../agents/AgentsWrapper";
import { CreateBtn } from "../../../components";
import { CommonApiDataContext } from "../../../context/CommonApiDataContext";
import BulkUploadBtn from "../../../components/buttons/BulkUploadBtn";

import BulkUpload from "../../../components/bulkUpload/BulkUpload";
import ModalDialog from "../../../components/modal/modalDialog";
import { moduleConfig } from "../../../config";

const Users = () => {
    const [data, setData] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(20);
    const [loading, setLoading] = useState(true);
    const [productId, setProductId] = useState(null);
    const [BulkUpMdlOpen, setBulkUpMdlOpen] = useState(false);
    const [search, setSearch] = useState({ searchText: "", searchBy: moduleConfig.users?.searchableItems[0]?.value });

    const history = useHistory();
    const accessToken = localStorage.getItem("accessToken");

    const { products, tenants, grades, handleOpenSnackbar, setKeys } = useContext(CommonApiDataContext);

    useEffect(() => {
        document.title = "Gateway Admin | Users";
        if (!isAuthenticated()) {
            history.push("/login");
        }
    }, [history]);

    useEffect(() => {
        setProductId((products && products[0]?.value) || null);
    }, [products]);

    useEffect(() => {
        setKeys({ prod: "product_id", tnt: "tenant_id", grad: "grade" });
    }, [setKeys]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const userData = await getUsers(accessToken, currPage, limit, productId);
                const updatedData = userData?.users?.map((item) => ReplaceIdsWithLabels(item));

                setCurrPage(userData?.currentPage);
                setTotalPages(userData?.totalPages);
                setData(updatedData);
                setLoading(false);
            } catch (error) {
                handleOpenSnackbar(true, error?.response?.data?.detail, "error");
                setLoading(false);
                console.log(error);
            }
        };
        productId && fetchData();
    }, [currPage, limit, productId]);

    const ReplaceIdsWithLabels = (item) => {
        // const UP = item?.product_ids?.map((prodId) => {
        //     const product = products?.find((prod) => prod?.value === prodId);
        //     return product ? product?.label : prodId;
        // });

        // const UG = item?.grade_ids?.map((gradeId) => {
        //     const grade = grades?.find((itemData) => itemData?.value === gradeId);

        //     return grade ? grade?.label : gradeId;
        // });
        const UP = products?.filter((e) => e.value === Number(item?.product_id))[0]?.label;
        const UG = grades?.filter((e) => e.value === Number(item?.grade))[0]?.label;
        const UT = tenants?.filter((e) => e.value === Number(item?.tenant_id))[0]?.label;

        return { ...item, product_id: UP, tenant_id: UT, grade: UG };
    };

    const handleToggle = (item) => {
        let chngIsAtvTo = null;

        const updatedList = data.map((e) => {
            if (e?.uam_id === item?.uam_id) {
                const isActive = item?.is_active;
                chngIsAtvTo = !isActive;
                return {
                    ...e,
                    is_active: !isActive,
                };
            }
            return e;
        });
        setData(updatedList);
        const ids = {
            user_ids: [item?.uam_id],
        };
    };

    const closeModal = () => {
        setBulkUpMdlOpen(false);
    };

    return (
        <section className="section">
            <div className="title-createbtn-box">
                <h1 className="title">Users</h1>
                <div>
                    <CreateBtn />
                    <BulkUploadBtn setOpen={setBulkUpMdlOpen} />
                </div>
            </div>
            <ModalDialog open={BulkUpMdlOpen} onClose={() => setBulkUpMdlOpen(false)} title="Users Bulk Upload">
                <BulkUpload />
            </ModalDialog>
            <Table
                navigationId={6}
                pageTitle="Users"
                data={data}
                loading={loading}
                onToggle={handleToggle}
                setCurrPage={setCurrPage}
                setTotalPages={setTotalPages}
                currPage={currPage}
                totalPages={totalPages}
                limit={limit}
                setLimit={setLimit}
                EditForm={AgentsWrapper}
                setProductIds={setProductId}
                setSearch={setSearch}
                search={search}
            />
        </section>
    );
};

export default Users;
