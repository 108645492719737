import React, { useContext } from "react";
import Button from "@mui/material/Button";
import GroupsIcon from "@mui/icons-material/Groups";
import { CommonApiDataContext } from "../../context/CommonApiDataContext";

const BulkUploadBtn = (props) => {
    const { setOpen } = props;
    const { handleOpenSnackbar, access } = useContext(CommonApiDataContext);

    const openModel = async () => {
        access === "admin" ? handleOpenSnackbar(true, "Access denied!", "error") : setOpen(true);
    };

    return (
        access !== "other" && (
            <Button onClick={openModel} variant="contained" startIcon={<GroupsIcon />} sx={{ marginLeft: "10px" }}>
                BulkUpload
            </Button>
        )
    );
};

export default BulkUploadBtn;
